export const Headline = () => {
	return (
		<div className="pages-title">
			Customized <br/>
			Web Mapping <br/>
			Applications
		</div>
	)
}

Headline.displayName="Headline";